import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// CONSTANTS
import { ROUTE_ROOT } from '@/constants/routes';

// UTILITY
import { updateUrl } from '@/utility/common';
import { getExternalUrlParams } from '@/utility/analytics';
import { checkIsAbsoluteUrl } from '@/utility/routes';

// COMPONENTS
import RouterLink from '@/components/Link';

// STYLES
import { LogoContainer } from './styles';

const LogoPartner = ({ to, location, externalLinkClick, ...restProps }) => {
  const partnerData = useSelector(({ partner }) => partner.data);
  const partnerSettings = partnerData.settings || {};
  const externalUrlParams = getExternalUrlParams();
  const url = partnerSettings.header_link || to;
  const href = updateUrl(url, externalUrlParams);
  const isExternalLink = checkIsAbsoluteUrl(href);

  const logoContent = <img src={partnerData.logo} alt={partnerData.partner_name} />;

  return (
    <LogoContainer className="logo-partner" {...restProps}>
      {isExternalLink ? (
        <a href={href} target="_blank" rel="noreferrer" onClick={externalLinkClick}>
          {logoContent}
        </a>
      ) : (
        <RouterLink to={href} location={location}>
          {logoContent}
        </RouterLink>
      )}
    </LogoContainer>
  );
};

LogoPartner.defaultProps = {
  to: ROUTE_ROOT,
  location: '',
  externalLinkClick: () => {},
};

LogoPartner.propTypes = {
  to: PropTypes.string,
  location: PropTypes.string,
  externalLinkClick: PropTypes.func,
};

export default React.memo(LogoPartner);
