import { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import colors, {
  WHITE,
  MAIN_BLACK,
  PORCELAIN,
  WOODSMOKE,
  WOODSMOKE_2,
  BURNT_SIENNA,
  PURPLE,
  AZURE_RADIANCE,
  SANTAS_GRAY,
  SHAMROCK,
  ROYAL_BLUE_2,
  FUCHSIA_BLUE,
  HINT_OF_RED,
  MYSTIC,
  CORN,
} from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

// ASSETS
import '@/assets/fonts/icomoon/style.css';
import markerBg from '@/assets/images/marker-bg.svg';

import bridgewiredStyles from './partners/bridgewiredStyles';
import centuryiframeStyles from './partners/centuryiframeStyles';
import commonPartnerStyles from './partners/commonPartnerStyles';
import cspireStyles from './partners/cspireStyles';
import epbstyles from './partners/epbStyles';
import fibermefastStyles from './partners/fibermefastStyles';
import htcStyles from './partners/htcStyles';
import iframeStyles from './partners/iframeStyles';
import medinaStyles from './partners/medinaStyles';
import metronetStyles from './partners/metronetStyles';
import montanastyles from './partners/montanaStyles';
import quantumStyles from './partners/quantumStyles';
import windstreamStyles from './partners/windstreamStyles';
import wintekStyles from './partners/wintekStyles';
import wowStyles from './partners/wowStyles';
import pbbStyles from './partners/pbbStyles';
import frontierStyles from './partners/frontierStyles';
import vermontelStyles from './partners/vermontelStyles';
import leelouStyles from './partners/leelouStyles';
import nctcvipStyles from './partners/nctcvipStyles';
import hyperfiberStyles from './partners/hyperfiberStyles';
import calStyles from './partners/calStyles';
import glofiberStyles from './partners/glofiberStyles';
import fidiumStyles from './partners/fidiumStyles';

const fontFamily = "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif";

const AppWrapper = createGlobalStyle`

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins-Light'),
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Light.woff2`}) format('woff2');
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Light.woff`}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins-Regular'),
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Regular.woff2`}) format('woff2');
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Regular.woff`}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins-Medium'),
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Medium.woff2`}) format('woff2');
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Medium.woff`}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins-SemiBold'),
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-SemiBold.woff2`}) format('woff2');
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-SemiBold.woff`}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins-Bold'),
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Bold.woff2`}) format('woff2');
       url(${`${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Bold.woff`}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  * {
    box-sizing: border-box;
  }

  html {
    min-height: 100%;
    font-size: 10px;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  body {
    font-family: ${p => p.theme.typography.fontFamily.join(', ') || fontFamily};
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 400;
    background-color: ${p => p.theme?.palette?.background?.default || colors.bg};
    color: ${p => p.theme.palette.text.primary || colors.text};
    margin: 0;
    padding: 0;

    @media screen and (max-width: ${breakpoints.xs}) {
      .xs-start {
        justify-content: flex-start;
        &.already-have {
          .checkbox-card {
            height:90px;
          }
        }
      }

      .hidden-xs {
        display: none;
      }
    }
  }

  .App {
    min-width: 360px;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
  }

  a {
    text-decoration: none;
  }

  b {
    font-weight: 500;
  }

  // Typography
  h1 {
    font-size: 6rem;
    line-height: 6.4rem;
    font-weight: 400;
  }

  h2 {
    font-size: 4.2rem;
    line-height: 4.6rem;
    font-weight: 400;
  }

  h3 {
    font-size: 3.5rem;
    line-height: 4rem;
    font-weight: 600;
  }

  h4 {
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 600;
  }

  h5 {
    font-size: 1.9rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  h6 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .p1, .p2, .p3, .p4, .p5, .body3 {
    margin: 0;
  }

  .p1 {
    font-size: 2.2rem;
    line-height: 3rem;
    font-weight: 300;
  }

  .p2 {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 500;
  }

  .p3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
  }

  .p4 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
  }

  .p5 {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .body3 {
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 400;
  }

  .content-visibility {
    &--auto {
      content-visibility: auto;
    }

    &--hidden {
      content-visibility: hidden;
    }
  }

  .subtitle-secondary {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 7px;
    margin-bottom: 20px;
    @media (max-width: ${breakpoints.xs}) {
      font-size: 1.3rem;
    }
  }

  .text {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-uppercase {
      text-transform: uppercase;
    }

    &-capitalize {
      text-transform: capitalize;
    }

    &-lowercase {
      text-transform: lowercase;
    }

    &-regular {
      font-weight: 400;
    }

    &-medium {
      font-weight: 500;
    }

    &-semibold {
      font-weight: 600;
    }

    &-bold {
      font-weight: 600;
    }

    &-underline {
      text-decoration: underline;
    }

    &-smaller {
     transform: scale(0.8);
    }
  }

  .hidden {
    display: none;
  }

  .hiddenVisibility {
    visibility: hidden;
  }

  .break-spaces {
    white-space: break-spaces;
  }

  .nowrap {
    white-space: nowrap;
  }

  .d-block {
    display: block;
  }

  .m-0 {
    margin: 0;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-25 {
    margin-left: 25px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-25 {
    margin-top: 25px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-t-60 {
    margin-top: 60px;
  }

  .m-b-0 {
    margin-bottom: 0;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-25 {
    margin-bottom: 25px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-35 {
    margin-bottom: 35px;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }

  .m-b-80 {
    margin-bottom: 80px;
  }

  .p-l-30 {
    padding-left: 30px;
  }

  .shrink-0 {
    flex-shrink: 0;
  }

  .pos-relative {
    position: relative;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }

  .cut-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .d-rtl {
    direction: rtl;
  }

  // Page Content
  .page-content {
    padding: 0 15px;
    max-width: 1140px;
    margin: 0 auto;
    height: 100%;
    width: 100%;

    &--no-padding {
      padding: 0;
    }

    //@media screen and (max-width: {breakpoints.sm}) {
    //  padding: 28px 16px 16px;
    //}
  }

  .section {
    padding: 110px 0;

    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 70px 0;
    }

    @media screen and (max-width: ${breakpoints.xs}) {
      padding: 60px 0;
    }

    &--light-grey {
      background-color: ${HINT_OF_RED};
    }

    &--grey {
      background-color: ${PORCELAIN};
    }

    &--white {
      background-color: ${WHITE};
    }

    &--dark {
      background-color: ${MAIN_BLACK};
      color: ${WHITE};
    }

    &--deep-dark {
      background-color: ${WOODSMOKE};
      color: ${WHITE};
    }

    &--deep-dark-2 {
      background-color: ${WOODSMOKE_2};
      color: ${WHITE};
    }

    &--green {
      background-color: ${SHAMROCK};
    }

    &--purple {
      background-color: ${ROYAL_BLUE_2};
      color: ${WHITE};
    }

    &--fuchsia-blue {
      background-color: ${FUCHSIA_BLUE};
      color: ${WHITE};
    }
  }

  // Static Pages
  .static-pages {
    &__wrapper {
      padding: 83px 0 130px;

      @media screen and (max-width: ${breakpoints.sm}) {
        padding: 53px 0 90px;
      }

      @media screen and (max-width: ${breakpoints.xs}) {
        padding: 23px 0 50px;
      }
    }

    &__content {
      max-width: 920px;
      margin: 0 auto;
    }

    h1, h2 {
      padding: 34px 0 12px;

      @media (max-width: ${breakpoints.xs}) {
        padding-top: 24px;
      }
    }

    h3, h4 {
      padding: 30px 0 3px;

      @media (max-width: ${breakpoints.xs}) {
        padding-top: 20px;
      }
    }

    h1 {
      font-weight: 600;
    }

    h2 {
      line-height: 4.9rem;
      font-weight: 600;
    }

    h4 {
      line-height: 3.5rem;
      font-weight: 600;
    }

    h5 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 600;
    }

    h6 {
      line-height: 2.1rem;
      font-weight: 600;
    }

    a {
      color: ${p => p.theme.palette.primary.main};
      font-weight: 600;

      word-break: break-word;
      text-decoration: none;
    }

    hr {
      margin: 8px 0;
    }

    p {
      padding: 10px 0 20px;
      line-height: 3rem;
    }

    ul {
      padding: 30px 0 20px;
      margin: 0;
      list-style: none;

      @media (max-width: ${breakpoints.xs}) {
        padding: 20px 0 10px;
      }

      li {
        margin-bottom: 40px;
        display: flex;
        align-items: flex-start;

        &:last-of-type {
          margin-bottom: 0;
        }

        h5 {
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
        }

        p {
          &:first-of-type {
            padding-top: 0;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }

        &:before {
          content: '\\2022';
          color: ${colors.text};
          font-weight: bold;
          display: inline-block;
          padding-right: 12px;
          font-size: 2.2rem;

          @media (max-width: ${breakpoints.xs}) {
            padding-right: 8px;
          }
        }
      }
    }

    .title-container {
      padding-bottom: 60px;
    }

    .sub-section {
      h5 {
        padding: 20px 0 0;
      }

      h6 {
        padding: 35px 0 1px;
        font-weight: 600;
      }

      ul, p {
        padding: 5px 0 10px;
      }

      li {
        margin-bottom: 0;

        &:before {
          padding-right: 12px;
          font-size: 16px;

          @media (max-width: ${breakpoints.xs}) {
            padding-right: 8px;
          }
        }
      }
    }

    .pt-10 {
      padding-top: 10px !important;
    }

    .pb-15 {
      padding-bottom: 15px !important;
    }

    .pb-20 {
      padding-bottom: 20px !important;
    }
  }

  // Text color
  .text-color {

    &--default {
      color: ${colors.text}
    }

    &--white {
      color: ${WHITE};
    }

    &--purple {
      color: ${PURPLE};
    }

    &--primary {
      color: ${p => p.theme.palette.primary.main};
    }

    &--error {
      color: ${p => p.theme.palette.error.main};
    }

    &--info {
      color: ${p => p.theme.palette.info.main};
    }

    &--warning {
      color: ${p => p.theme.palette.warning.main};
    }

    &--blue {
      color: ${AZURE_RADIANCE};
    }

    &--grey {
      color: ${SANTAS_GRAY};
    }

    &--yellow {
      color: ${CORN};
    }
  }

  // Cursor
  .cursor {
    &--default {
      cursor: default;
    }
    &--pointer {
      cursor: pointer;
    }
  }

  // Markers
  .marker {
    &--el,
    &:before {
      position: absolute;
      top: -14px;
      left: -14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      color: ${p => p.partner?.theme?.markerText || p.theme.palette.primary.contrastText};
      background: ${p => p.theme.palette.primary.main};
      -webkit-mask-image: url(${markerBg});
      mask-image: url(${markerBg});
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      //border-radius: 25px;
      z-index: 1;

      @media screen and (max-width: ${breakpoints.xs}) {
        width: 46px;
        height: 46px;
        font-size: 10px;
        top: -10px;
        left: -10px;
      }
    }

    &--static {
      position: static;
      display: inline-flex;
      margin-bottom: 7px;
    }

    &--small {
      font-size: 9px;
    }

    &--purple {
      background-color: ${ROYAL_BLUE_2};
    }

    &--super-deal {
      &:before {
        content: 'DEAL';
      }
    }
  }

  // Marker Labels
  .marker-label {
    position: absolute;
    top: 0;
    right: 0;
    color: ${p => p.theme.palette.primary.contrastText};
    background: ${p => p.theme.palette.primary.main};
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breadcrumbs {
    &--light {
      a {
        color: ${rgba(WHITE, 0.4)};
      }

      span {
        color: ${WHITE};
      }
    }

    &--dark {
      .MuiLink-root {
        color: ${rgba(MAIN_BLACK, 0.4)};
      }

      span {
        color: ${MAIN_BLACK};
      }
    }
  }

  .badge-dollar {
    position: relative;

    &:before {
      font-family: 'icomoon';
      content: "\\e931";
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${p => p.theme.palette.primary.main};
      color: ${p => p.theme.palette.text.primary};
      font-size: 12px;
      line-height: 1;
      width: 24px;
      height: 24px;
      transform: translate(30%, -30%);
      font-weight: bold;
    }

    &--small {
      &:before {
        width: 18px;
        height: 18px;
        font-size: 9px;
      }
    }

    &--large {
      &:before {
        width: 30px;
        height: 30px;
        font-size: 15px;
      }
    }
  }

  // Pros Cons List
  .pros-cons-list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    .pcl-item {
      display: flex;
      align-items: flex-start;

      margin-bottom: 20px;
      font-size: 1.6rem;
      line-height: 1.8rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      i {
        font-size: 1.8rem;
        margin-right: 10px;

        &.icon-check {
          color: ${p => p.theme.palette.primary.main};
        }

        &.icon-minus {
          color: ${BURNT_SIENNA};
        }
      }
    }

    &--square-icons {
      .pcl-item {
         line-height: 2.4rem;
      }

      i {
        &.icon-check {
          font-size: 1.2rem;
          background: ${p => rgba(p.theme.palette.primary.main, 0.25)};
          padding: 6px;
          border-radius: 4px;
        }
      }
    }

    &--horizontal {
      .pcl-item {
        display: inline-flex;
        margin-bottom: 0;

        &:not(:last-of-type) {
          margin-right: 30px;
        }
      }
    }

    &--with-icons {
      .pcl-item {
        &::before {
          content: "\\e907";
          font-family: 'icomoon';
          color: ${p => p.theme.palette.primary.main};
          font-size: 1.2rem;
          background: ${p => rgba(p.theme.palette.primary.main, 0.25)};
          border-radius: 4px;
          margin-right: 10px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }
      }
    }

    &--grey {
      .pcl-item {
        &::before {
          background-color: ${MYSTIC};
          color: ${MAIN_BLACK};
        }
      }

      i {
        &.icon-check {
          background-color: ${MYSTIC};
          color: ${MAIN_BLACK};
        }
      }
    }
  }

  .wizard-form {
    .toolbar__link.nextlink  {
      color: ${p => (p.theme.partnerStyles ? p.theme.palette.primary.contrastText : WHITE)};
      padding: 5px 16px;
    }
  }

  // MODAL VIDEO
  .modal-video {
    outline: none;

    .modal-video-close-btn {
      display: none !important;
    }
  }

  // MARKDOWN STYLES
  .markdown {
    pre {
      white-space: inherit;
    }
  }

  // WALKTOUR reset styles
  #walktour-tooltip-container:focus-visible {
    outline: none;
  }
/*
  // TEST
  .front-face-transition-enter {
      transform-style: preserve-3d;
      transition: all 1000ms ease;
      transform: rotateY(0deg);
  }
  .front-face-transition-enter-active {
      transform: rotateY(180deg);
  }
  .front-face-transition-enter-done {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
  }

  .back-face-transition-enter {
      transform-style: preserve-3d;
      transition: all 1000ms ease;
      transform: rotateY(0deg);
      display: block;
  }
  .back-face-transition-enter-active {
      transform: rotateY(-180deg);
      display: block;
  }
  .back-face-transition-enter-done {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
  }


  .card-front {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
  } */

  // PARTNER EXTRA STYLES
  ${p => {
    if (p.partner.slug) {
      return commonPartnerStyles;
    }
    return '';
  }}

  ${p => {
    let partnerStyles = '';

    switch (p.partner.slug) {
      case 'centuryiframe':
      case 'centurylink':
        partnerStyles = centuryiframeStyles(p.partner.theme);
        break;
      case 'epb':
      case 'epb-csr':
        partnerStyles = epbstyles;
        break;
      case 'montana':
      case 'montana-csr':
        partnerStyles = montanastyles;
        break;
      case 'metronet':
      case 'metronet-csr':
        partnerStyles = metronetStyles(p.partner.theme);
        // partnerStyles = metronetStyles;
        break;
      case 'wow':
      case 'wow-csr':
        partnerStyles = wowStyles;
        break;
      case 'windstream':
        partnerStyles = windstreamStyles;
        break;
      case 'quantum':
        partnerStyles = quantumStyles;
        break;
      case 'wintek':
        partnerStyles = wintekStyles;
        break;
      case 'cspire':
        partnerStyles = cspireStyles;
        break;
      case 'fibermefast':
        partnerStyles = fibermefastStyles;
        break;
      case 'htc':
        partnerStyles = htcStyles;
        break;
      case 'medina':
        partnerStyles = medinaStyles;
        break;
      case 'bridgewired':
        partnerStyles = bridgewiredStyles;
        break;
      case 'point-broadband':
        partnerStyles = pbbStyles;
        break;
      case 'frontier':
        partnerStyles = frontierStyles(p.partner.theme);
        break;
      case 'vermontel':
        partnerStyles = vermontelStyles(p.partner.theme);
        break;
      case 'leelou':
        partnerStyles = leelouStyles(p.partner.theme);
        break;
      case 'nctc_vip':
        partnerStyles = nctcvipStyles;
        break;
      case 'hyperfiber':
        partnerStyles = hyperfiberStyles;
        break;
      case 'cal':
        partnerStyles = calStyles;
        break;
      case 'glofiber':
        partnerStyles = glofiberStyles;
        break;
      case 'fidium':
        partnerStyles = fidiumStyles;
        break;
      default:
        break;
    }

    return p.extraStyles ? partnerStyles : '';
  }}

  ${p => {
    if (p.isIframe) {
      return iframeStyles;
    }
    return '';
  }}

`;

export default AppWrapper;
