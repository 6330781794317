import './modernPolyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import analytics from 'react-segment';

// CONSTANTS
import { segmentKey } from '@/constants/common';

// BUGSNAG
import bugsnagClient from '@/utility/bugsnagClient';

// LOCALIZATION
import '@/locale/i18n';

// STORE
import store from '@/store/configureStore';

// UTILITY
import localStore from '@/utility/store';
import { getSearchParams, isPageSpeedBot } from '@/utility/common';

// HOC
import ErrorBoundary from '@/hoc/ErrorBoundary';

// COMPONENTS
import ScrollToTop from '@/components/ScrollToTop';
import App from '@/App';

function checkAnlParam() {
  const params = getSearchParams(document.location.search);
  const anlParam = params.get('anl');
  const anlStoredParam = localStore.get('anl');

  if (anlParam) {
    localStore.set('anl', 'off');
  }

  return (anlParam && anlParam === 'off') || (anlStoredParam && anlStoredParam === 'off') || false;
}

const analyticIsOff = checkAnlParam();

export const allowAnalytic = Boolean(
  segmentKey && !isPageSpeedBot() && !analyticIsOff && analytics,
);

function runAnalytic() {
  if (allowAnalytic) {
    analytics.default.load(segmentKey);
  }
}

runAnalytic();

function render(retries = 2) {
  try {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <ErrorBoundary>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>,
    );
  } catch (error) {
    if (retries === 0) {
      throw error;
    }

    render(retries - 1);
  }
}

try {
  render();
} catch (error) {
  bugsnagClient.notify(new Error('React render is crashed', { cause: error }), event => {
    event.addMetadata('react', {
      document: document.documentElement.innerHTML,
    });
  });
}

export default store;
