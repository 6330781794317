export default {
  title: 'Subscription plans',
  manage: 'Manage Subscription',
  acc_info: ' Account Information',
  payment_date: 'Payment Date',
  active_until: 'Active until',
  steps_short: 'Step {{active}}/{{length}}',
  extra_cost_info:
    '*Sling Blue subscribers in Chicago, Los Angeles, New York, Philadelphia and San Francisco have access to three major networks – ABC, FOX and NBC – making the price in those markets $45 a month',
  trial_info: '{{trial_days}} day free trial. You will be billed starting {{charge_date}}',
  discount_info:
    '{{total_price}} is due now. Your monthly charge will be {{price}} starting from the next billing cycle.',
  discount_profit: 'You saved ${{price}}',
  device: 'Device',
  quantity: 'Quantity',
  shipping: 'Shipping',
  tax: {
    title: 'Tax',
    defaultText: 'Calculated at checkout',
  },
  slingOffer: {
    title: 'Air TV 2 + Antenna',
  },
  subscriptionPlan: {
    breadcrumb: 'Subscription Plan',
    title: 'Select a Subscription Plan',
  },
  supportInfo: {
    title: 'Contact Us',
    text:
      'If you encounter any issues or have questions, we encourage you to reach out to us for assistance at <a href="mailto:help@mybundle.tv">help@mybundle.tv.</a>',
  },
  payment: {
    history: 'Payment History',
    breadcrumb: 'Payment Method',
    title: 'Set Up Payment Method',
    subtitle: '<p>Please add your credit card for your {{app}} subscription.</p>',
    subtitle_virtualCard:
      '<p>Use your Streaming Rewards balance to pay for your {{app}} subscription.</p>',
    subtitle_roku: '<p>Please add your credit card to complete your {{app}} device purchase.</p>',
    partnerDescription: 'You will be charged by MyBundle, a strategic partner of {{name}}.',
    trial_info:
      'Your first {{trial_days}} days will be free. Afterwards you will be billed ${{price}} every month until you cancel, which you can do anytime in your MyBundle Profile',
    cancellationPolicy:
      'By clicking "{{action_name}}", you agree and authorize MyBundle to initiate your {{name}} subscription. You also agree to automatic monthly charges in advance at the current listed price until you cancel your subscription. No refunds or credits will be issued for prepaid or partial months of service. All prices, fees, packages, and offers are subject to change.',
    cancellationPolicyRoku:
      'By clicking {{action_name}}, you agree and authorize MyBundle to initiate your {{name}} purchase. You will receive a unique promo code to be used when you enter your shipping information. All prices, fees, packages, and offers are subject to change.',
  },
  registration: {
    breadcrumb: 'Account Creation',
    title: 'Registration for {{app}}',
    subtitle: '<p>Please enter the E-mail which will be used for {{app}} website/app.</p>',
    subtitle_plural:
      '<p>Please enter the E-mail and Password which will be used for {{app}} website/app.</p>',
    create_password_subtitle:
      '<p>We successfully created an account with your e-mail.</p><p>Please <b>click on "Create Password" button</b> to create a password for your account.</p>',
    create_password_desc: '"Create Password" button will redirect you to {{app}} website',
  },
  promocode: {
    breadcrumb: 'Promo Code',
    title: 'Your Special Promo Code',
    subtitle:
      '<p>You’ll be redirected to the Roku Streaming Stick 4K webpage to complete your order and delivery information. Please copy the promo code here and enter it in the “Coupon Code” field on the Roku webpage.</p>',
    transactionPending:
      'Your transaction is taking longer than usual to process. Once it is processed successfully, we will email you the promo code with instructions.',
    successDialog: {
      title: 'Success!',
      subtitle:
        'You’ve successfully placed an order for a Roku Streaming Stick 4K. Please pay close attention to emails from MyBundle & Roku for order and delivery details. Your Coupon Code is: {{code}}',
    },
  },
  registration_dialog: {
    title: 'Set Up Your {{app}} Account',
    more_services: {
      title: 'Just one more step! Complete your {{app}} account setup.',
      subtitle:
        "You've just set up your {{prev_app}} account. The next step is to complete your {{app}} account setup, so you can start enjoying content from both platforms.",
    },
    complete: {
      title: 'Awesome! You’re all set.',
      subtitle:
        'You’re all set! Now, just download {{apps_names}} on your preferred devices and enjoy!',
    },
  },
  mbLogin: {
    title: 'Log in to your MyBundle Account',
    desc:
      'Looks like you have an existing MyBundle account. Please enter your MyBundle password to continue with your {{app}} Registration.',
  },
  donation: {
    breadcrumb: 'Donation Setup',
    title: 'Donation Setup',
    subtitle: '<p>{{app}} is a free service, would you like to donate them to remove ads?</p>',
    donate_desc: 'Donate button will redirect you to {{app}} donation form',
  },
  interval: {
    day: 'Daily',
    month: 'Monthly',
    year: 'Yearly',
  },
  devices: {
    title: 'How to watch on your devices',
    desc:
      '<p>Search for the {{app}} app in the respective app store. Download the {{app}} app on your phone, tablet, or TV, sign into the app using the same login that you created.</p>',
    tastemade_desc:
      '<p>Follow the links below or search for Tastemade in your favorite app store to download the app and watch Tastemade’s shows and documentaries on your phone, tablet, or TV. You can also access over 12,000 premium recipes and a weekly meal plan to take the stress out of dinner time at <a href="https://www.tastemade.com/" target="_blank">tastemade.com</a>. Please log in using the same email and password you used to sign up on MyBundle.</p>',
    desc_not_found:
      '<p>Details about how to watch on your devices are coming soon, if you have any questions contact us at <a href="mailto:help@mybundle.tv">help@mybundle.tv</a>.</p>',
  },
  changed: {
    plan:
      'Your plan will change to <b>{{plan}}</b> {{addons}}<b>{{price}}/{{interval}}</b> on your next payment date <b>{{date}}</b>',
    addons: 'with <b>{{addons}} Add-on</b> ',
    addons_plural: 'with <b>{{addons}} Add-ons</b> ',
  },
  notifications: {
    verify_email: 'Please verify your E-mail',
    required_registration: 'Registration required',
    error_default: 'Whoops! Something went wrong',
    thanks_for_sub: 'Thank you for subscription',
    thanks_for_purchase: 'Thank you for purchase',
    payment_failed: 'Payment failed',
    payment_confirmed: 'Payment method confirmed',
    would_try_again: 'Would you like to try again?',
    canceled_title: 'Subscription was succesfully canceled',
    purchased_mybundle: 'Purchased through MyBundle',
    acc_created:
      '<p>Your account is ready to go! Check the info below to watch {{app}} on your devices.</p>',
    acc_created_vimeo:
      '<p>Your account is ready to go. Check the info below to watch {{app}} on your devices. If you still haven’t created a password for your account, you can do this by clicking on "Visit Site" button</p>',
    'acc_created_locast-free':
      '<p>Your free account was successfully created. You can manage your subscription plan anytime by clicking on "manage subscription" button.</p>',
    trial:
      '<p>Your free trial has been successfully started.</p><p>After your free trial ends, you will be charged <b>$ {{price}}</b> every {{interval}}.</p>',
    card_charged: '<p>Your card was successfully charged. The receipt was sent to your E-mail.</p>',
    registration: '<p>To start using the application, you need to create {{name}} account.</p>',
    registration_accounts:
      '<p>To start using the application, you need to create {{name}} accounts.</p>',
    card_canceled:
      '<p>You have canceled registration earlier. To start using the application, you need to create an account.</p>',
    canceled_desc:
      '<p>Your subscription was successfully canceled and will end on <b>{{date}}.</b> Up to and including this date you can renew your subscription.</p>',
    canceled_and_ended_desc:
      '<p>Your subscription was successfully canceled and ended on <b>{{date}}.</b></p>',
    payment_error_desc: '<p>An error occurred while trying to pay.</p><p>Please, try again.</p>',
    registration_error_desc: '<p>It seems like this email already exists on {{app}}.</p>',
    pastDue: {
      title: 'We are having trouble processing your payment',
      desc:
        "<p>To make sure you don't lose access to {{app}}, please update your payment information.</p>",
    },
    status: {
      token_expired:
        'Looks like your session has timed out. Please re-enter your newly created {{app}} username and password to continue',
      subscription_already_exists: 'Subscription already exists',
    },
    spec_offer_title: 'Add your Shipping Address',
    spec_offer:
      'You’ve selected to receive a Free Antenna + Air TV 2 device to add local channels to your Sling TV Service. Please add your shipping address so we can send it your way.',
    activation_info:
      'Please click <a>here</a> to activate your credential with {{name}} and begin managing and streaming. ',
  },
  alerts: {
    registration_def: {
      title: 'Great! Let’s set up your {{name}} account.',
      message:
        '<p>Starting now, you have the {{app_name}} with us. Go ahead and set up your {{name}} account down below, so you can start streaming.</p>',
    },
    registration_def_bundle: {
      title: 'Great! Let’s set up your {{name}} accounts.',
      message:
        '<p>Starting now, you have the {{app_name}} with us. Go ahead and set up your {{name}} accounts down below, so you can start streaming. </p>',
    },
    registration_def_later: {
      title: 'Don’t forget to set up your {{name}} account.',
      message: '<p>Set up your own {{name}} account down below, so you can start streaming. </p>',
    },
  },
  // CANCELLATION
  cancelDialog: {
    title: 'Subscription cancellation',
    subtitle: 'Are you sure you want to cancel your subscription?',
  },

  // RENEW
  renewDialog: {
    title: 'Renew Subscription',
    subtitle:
      'Do you want to continue subscription and continue charging your card on the future payment dates?',
  },

  // Success Dialog with activation link
  successWithActivationDialog: {
    title: 'Yay, Success! 🎉',
    subtitle:
      'Before you leave..\nDon’t forget to activate your credential with {{name}} and begin managing and streaming.',
  },

  specialOffer: {
    title: 'Exclusive Offer',
    text:
      'Would you like a FREE antenna + AirTV 2 device to add FREE local channels to your Sling TV service?',
  },

  srWithoutBalance: {
    payment: {
      title: 'Payment',
      subtitle: 'Checkout with your Streaming Choice Balance.',
      text: '',
    },
  },
};
