import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';

// CONSTANTS
import {
  CONTENT_CHANGE_STATUS,
  CONTENT_MEDIA,
  CONTENT_GET_REVIEWS,
  CONTENT_ADD_REVIEW,
} from '@/constants/api';
import { contentTypes } from '@/constants/watchList';

// UTILITY
import { getLink } from '@/utility/routes';
import { getPartnerSlug, showErrorNotification } from '@/utility/saga';

// STORE
import {
  ContentActions,
  ShowDetailsActions,
  MovieDetailsActions,
  UserActions,
  NotifActions,
} from '@/store/actions';
import { AuthSelectors } from '@/store/selectors';

// LOCALE
import { t } from '@/locale/i18n';

const {
  getContentMedia,
  changeContentStatus,
  getContentReviews,
  addContentReview,
} = ContentActions;
const { updateMovieMedia } = MovieDetailsActions;
const { updateShowMedia } = ShowDetailsActions;
const { userGetContentCount } = UserActions;

const { pushSuccessNotificationAction } = NotifActions;

export function* getContentMediaSaga(action) {
  yield put(getContentMedia.start());

  const { content_id, type } = action.payload;
  const url = getLink(CONTENT_MEDIA, { content_id });

  try {
    const { data: respData } = yield axios.get(url);

    if (type === contentTypes.movie) {
      yield put(updateMovieMedia.init({ data: respData }));
    } else if (type === contentTypes.show) {
      yield put(updateShowMedia.init({ data: respData }));
    }

    yield put(getContentMedia.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getContentMedia.fail());
  }
}

export function* changeContentStatusSaga(action) {
  const { content_id, status, callback } = action.payload;

  yield put(changeContentStatus.start({ content_id }));

  const url = getLink(CONTENT_CHANGE_STATUS, { content_id });
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    yield axios.post(url, { status });

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    yield put(changeContentStatus.success({ content_id, status }));

    if (callback) callback();

    yield put(changeContentStatus.stop({ content_id }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(changeContentStatus.fail({ content_id }));
  }
}

export function* getContentReviewsSaga(action) {
  yield put(getContentReviews.start());

  const { content_id, params, clearCache = false } = action.payload;
  const partner = yield call(getPartnerSlug);

  const url = getLink(CONTENT_GET_REVIEWS, { content_id });

  if (partner) params.partner = partner;

  try {
    const { data: respData } = yield axios.get(url, { params, clearCacheEntry: clearCache });

    yield put(
      getContentReviews.success({
        data: respData.data,
        meta: respData.meta,
        user_review: respData.user_review,
        ratings_summary: respData.ratings_summary,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getContentReviews.fail());
  }
}

export function* addContentReviewSaga(action) {
  yield put(addContentReview.start());

  const { content_id, data, onSuccess } = action.payload;
  const url = getLink(CONTENT_ADD_REVIEW, { content_id });

  try {
    const { data: respData } = yield axios.post(url, data);

    yield call(getContentReviewsSaga, {
      payload: { content_id, params: { page: 1, per_page: 3 }, clearCache: true },
    });

    yield put(pushSuccessNotificationAction(t('notification.successfullySentReview')));

    if (onSuccess) onSuccess();

    yield put(
      addContentReview.success({
        data: respData.data,
        meta: respData.meta,
        user_review: respData.user_review,
        ratings_summary: respData.ratings_summary,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addContentReview.fail());
  }
}

export default getContentMediaSaga;
