import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Button,
  Link,
  Hidden,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Clear';

// CONSTANTS
import { appsListStatuses } from '@/constants/app';
import { LOCATIONS_ONBOARDING_PAGE } from '@/constants/locations';

// UTILITY
import store from '@/utility/store';

// STORE
import { UserActions, OnboardingActions, AppDetailsActions, NotifActions } from '@/store/actions';

// HOC COMPONENTS
import withMediaQuery from '@/hoc/withMediaQuery';

// COMPONENTS
import CheckBoxCard from '@/components/Form/CheckBoxCard';
import Loader from '@/components/Loader/Loader';
import Slide from '@/components/Transitions/Slide';
import Logo from '@/components/Logo';
import LogoPartner from '@/components/LogoPartner/LogoPartner';

// HELP
import SearchAutoComplete from './help/SearchAutoComplete';
import AppsList from './help/AppsList';
import AddedApps from './help/AddedApps';
import Hero from './help/Hero';

// STYLES
import { Wrapper, FooterAppBar } from './styles';
import { externalLink } from '@/utility/analytics';

const initState = {
  selectedApps: [],
  search: '',
  autocompleteValue: null,
};

class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = initState;

    this.debounceduserAppsAutocomplete = debounce(props.userAppsAutocomplete, 500);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.updateIsOpen();
  }

  componentDidUpdate(prevProps) {
    const { user_apps, user } = this.props;

    if (prevProps.user_apps !== user_apps && user_apps.length) {
      this.setState({ selectedApps: user_apps });
    }

    if (prevProps.user?.completed_apps_onboard !== user?.completed_apps_onboard) {
      this.updateIsOpen();
    }
  }

  onEnter = () => {
    const { isAuth, selectedMyApps, getOnboardingApps } = this.props;

    if (isAuth) {
      getOnboardingApps();
    }

    if (!isAuth) {
      this.setState({ selectedApps: selectedMyApps });
    }
  };

  updateIsOpen = () => {
    const { user, onboardingSetState } = this.props;

    const isOpen = Boolean(user && !user.completed_apps_onboard);

    if (isOpen) {
      setTimeout(() => {
        onboardingSetState(isOpen);
      }, 100);
    }
  };

  handleSearchChange = (event, searchValue, reason) => {
    if (event.persist) {
      event.persist();
    }

    if (event?.keyCode === 13 && reason === 'reset') {
      return;
    }

    const { search } = this.state;
    let newInputValue = searchValue;

    if (reason === 'reset' || reason === 'select-option' || reason === 'clear') {
      newInputValue = '';
    }

    if (search !== newInputValue) {
      const submitData = {
        search_string: newInputValue,
      };

      this.setState({ search: newInputValue });

      this.debounceduserAppsAutocomplete(submitData);
    }
  };

  updateSelectedApps = (event, data) => {
    const { selectedApps } = this.state;
    let updatedData = [...selectedApps];
    const hasApp = !!selectedApps.find(app => app.id === data.id);

    if (hasApp) {
      updatedData = updatedData.filter(app => app.id !== data.id);
    } else {
      updatedData.push(data);
    }

    this.setState({ selectedApps: updatedData });
  };

  handleUnselectAll = () => {
    this.setState({ selectedApps: [] });
  };

  handleSearchSelect = (event, value = {}, reason, setOpen) => {
    if (event.persist) {
      event.persist();
    }

    const { selectedApps } = this.state;
    const { t, pushNotification } = this.props;

    if (reason === 'select-option') {
      const hasApp = !!selectedApps.find(app => app.id === value.id);

      if (hasApp) {
        pushNotification({
          message: t('notification.appAlreadySelected', { appName: value.name }),
          options: {
            variant: 'success',
          },
        });
      } else {
        const apps = [...selectedApps, value];
        this.setState({ selectedApps: apps });
        pushNotification({
          message: t('notification.appSuccessfullyAdded', { appName: value.name }),
          options: {
            variant: 'success',
          },
        });
      }
      setOpen(false);
    }
  };

  handleSearchClear = () => {
    const { search } = this.state;
    const { userAppsAutocomplete } = this.props;

    if (search) {
      const submitData = {
        search_string: '',
      };

      this.setState({ search: '' }, () => userAppsAutocomplete(submitData));
    }
  };

  handleSearchClose = () => {
    this.setState({ search: '' });
  };

  handleSubmit = () => {
    const { selectedApps } = this.state;
    const {
      isAuth,
      user,
      appListUpdateStatus,
      onboardingComplete,
      onboardingSetState,
      userUpdateApps,
      userGetRecommendedApps,
      onboardingProps,
      updateSelectedApps,
    } = this.props;

    if (isAuth) {
      const onboardingCompleted = user && user.completed_apps_onboard;
      const sendData = {
        apps: selectedApps.map(app => ({ app_id: app.id, status: appsListStatuses.have })),
        replace: 1,
      };

      const callback = () => {
        if (!onboardingCompleted) {
          onboardingComplete();
        } else {
          userGetRecommendedApps();
        }

        userUpdateApps(selectedApps);

        if (onboardingProps.callback) onboardingProps.callback(sendData);

        onboardingSetState(false);
      };

      appListUpdateStatus(sendData, callback);
    } else {
      updateSelectedApps(selectedApps);
      store.set('my_apps', selectedApps);
      onboardingSetState(false);
    }
  };

  handleOnboardingClose = () => {
    const { user, onboardingSetState, onboardingComplete } = this.props;

    if (user && !user.completed_apps_onboard) {
      onboardingComplete();
    }

    onboardingSetState(false);
  };

  setInitState = () => {
    this.setState(initState);
  };

  handleExternalLink = ({ target }) => {
    const { partner } = this.props;
    const href = target.href || target.parentNode?.href;
    const properties = {
      location: 'onboarding',
      href,
      partner: partner.partner_slug,
      partner_id: partner.partner_id,
    };

    externalLink(properties);
  };

  render() {
    const { selectedApps, search, autocompleteValue } = this.state;
    const {
      t,
      matches,
      onboardingIsOpen,
      loadingApps,
      loadingComplete,
      loadingStatuses,
      apps,
      autocompleteData,
      autocompleteDataLoading,
      isAuth,
      user,
      partner,
    } = this.props;
    const selectedAppsIds = selectedApps.map(app => app.id);
    const onboardingCompleted = user && user.completed_apps_onboard;

    const saveBtn = (
      <Button
        className="save-btn"
        variant="contained"
        color="primary"
        size="medium"
        onClick={this.handleSubmit}
        disabled={isAuth ? loadingComplete || loadingStatuses || !selectedApps.length : false}
      >
        {t('buttons.save')}
      </Button>
    );

    const logo = partner.logo ? (
      <LogoPartner
        location={LOCATIONS_ONBOARDING_PAGE}
        externalLinkClick={this.handleExternalLink}
      />
    ) : (
      <Logo location={LOCATIONS_ONBOARDING_PAGE} />
    );

    return (
      <Wrapper
        className="onboarding"
        fullScreen
        open={onboardingIsOpen}
        disableRestoreFocus
        disablePortal
        TransitionComponent={Slide}
        closeAfterTransition
        TransitionProps={{ onEnter: this.onEnter, onExited: this.setInitState }}
      >
        <AppBar>
          <Toolbar className="onboarding__header">
            {logo}
            <div className="onboarding__header-controls">
              <IconButton onClick={this.handleOnboardingClose}>
                <RemoveIcon fontSize="large" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Hero />
        <div className="onboarding__content-wrap">
          <div className="onboarding__content page-content">
            <div className="onboarding__title-container">
              <Typography className="m-b-15" variant={matches ? 'h5' : 'h4'} align="center">
                {t('onboarding.title')}
              </Typography>
            </div>

            <div className="onboarding__search-container">
              <SearchAutoComplete
                className="search-apps"
                freeSolo
                inputValue={search}
                value={autocompleteValue}
                onInputChange={this.handleSearchChange}
                onChange={this.handleSearchSelect}
                options={autocompleteData}
                loading={autocompleteDataLoading}
                openOnFocus
                inputProps={{
                  onBlur: this.handleSearchClear,
                }}
                onClose={this.handleSearchClose}
                analyticsLocation={LOCATIONS_ONBOARDING_PAGE}
              />
            </div>

            <div className="onboarding__apps">
              <Hidden smUp>
                <Typography className="m-b-15" align="center" variant="h6">
                  {t('onboarding.popularApps')}
                </Typography>
              </Hidden>
              <Grid
                className="onboarding__apps-container"
                container
                spacing={1}
                justifyContent="center"
              >
                {loadingApps ? (
                  <Loader active />
                ) : (
                  <>
                    {apps.map(app => {
                      return (
                        <Grid item xs={3} sm={2} md={2} key={app.id}>
                          <CheckBoxCard
                            name="apps"
                            value={app.id}
                            imageSrc={app.thumbnail}
                            title={app.name}
                            handleChange={event => this.updateSelectedApps(event, app)}
                            checkedValue={selectedAppsIds}
                          />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
              <Hidden smUp>
                {!!selectedApps.length && (
                  <AddedApps
                    apps={selectedApps}
                    onAppRemove={this.updateSelectedApps}
                    onUnselectAll={this.handleUnselectAll}
                  />
                )}
              </Hidden>
            </div>
          </div>
        </div>

        <FooterAppBar
          component="footer"
          className={`onboarding__footer o-footer ${
            !selectedApps.length ? 'o-footer--no-apps' : ''
          }`}
        >
          <Toolbar className="o-footer__toolbar">
            <Hidden xsDown>
              {selectedApps.length ? (
                <AppsList
                  apps={selectedApps}
                  onAppRemove={this.updateSelectedApps}
                  onUnselectAll={this.handleUnselectAll}
                  search={search}
                />
              ) : (
                <div />
              )}
            </Hidden>
            <div className="o-footer__controls">
              {!onboardingCompleted && isAuth ? (
                <Link component="button" onClick={this.handleOnboardingClose}>
                  {t('buttons.doThisLater')}
                </Link>
              ) : (
                <Hidden smUp>
                  <Link component="button" color="textPrimary" onClick={this.handleOnboardingClose}>
                    {t('buttons.cancel')}
                  </Link>
                </Hidden>
              )}
              {saveBtn}
            </div>
          </Toolbar>
        </FooterAppBar>
        <Toolbar style={{ zIndex: -1 }} />
      </Wrapper>
    );
  }
}

Onboarding.propTypes = {
  t: PropTypes.func.isRequired,
  matches: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  apps: PropTypes.array.isRequired,
  user_apps: PropTypes.array.isRequired,
  selectedMyApps: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingApps: PropTypes.bool.isRequired,
  loadingComplete: PropTypes.bool.isRequired,
  loadingStatuses: PropTypes.bool.isRequired,
  autocompleteDataLoading: PropTypes.bool.isRequired,
  autocompleteData: PropTypes.array.isRequired,
  userGetRecommendedApps: PropTypes.func.isRequired,
  userAppsAutocomplete: PropTypes.func.isRequired,
  userUpdateApps: PropTypes.func.isRequired,
  getOnboardingApps: PropTypes.func.isRequired,
  onboardingComplete: PropTypes.func.isRequired,
  onboardingSetState: PropTypes.func.isRequired,
  updateSelectedApps: PropTypes.func.isRequired,
  onboardingProps: PropTypes.object.isRequired,
  appListUpdateStatus: PropTypes.func.isRequired,
  pushNotification: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  onboardingIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth, onboarding, appDetails, user, partner }) => ({
  onboardingIsOpen: onboarding.isOpen,
  isAuth: auth.isAuth,
  user: auth.user,
  apps: onboarding.apps,
  user_apps: onboarding.user_apps.have || [],
  selectedMyApps: onboarding.selectedMyApps,
  loading: onboarding.loadings.complete,
  loadingApps: onboarding.loadings.apps,
  loadingComplete: onboarding.loadings.complete,
  onboardingProps: onboarding.onboardingProps,
  loadingStatuses: appDetails.loadings.statusesLoading,
  autocompleteDataLoading: user.loadings.autocompleteData,
  autocompleteData: user.autocompleteData,
  partner: partner.data,
});

const mapDispatchToProps = dispatch => {
  return {
    userAppsAutocomplete: params => dispatch(UserActions.userAppsAutocomplete.action({ params })),
    userGetRecommendedApps: () => dispatch(UserActions.userGetRecommendedApps.action()),
    userUpdateApps: apps => dispatch(UserActions.userUpdateApps.action({ apps })),
    getOnboardingApps: () => dispatch(OnboardingActions.getOnboardingApps.action()),
    onboardingComplete: () => dispatch(OnboardingActions.onboardingComplete.action()),
    updateSelectedApps: data =>
      dispatch(OnboardingActions.onboardingUpdateSelectedApps.action({ data })),
    onboardingSetState: state => dispatch(OnboardingActions.onboardingSetState.action({ state })),
    appListUpdateStatus: (data, callback) => {
      dispatch(AppDetailsActions.appListUpdateStatus.action({ data, callback }));
    },
    pushNotification: params => dispatch(NotifActions.pushNotification.action(params)),
  };
};

export default compose(
  React.memo,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withMediaQuery({ matches: theme => theme.breakpoints.down('xs') }),
)(Onboarding);
