import axios from 'axios';
import { put, select, call, fork } from 'redux-saga/effects';

// CONSTANTS
import {
  MOVIE_DETAILS,
  MOVIE_STREAMING_DATA,
  MOVIE_SIMILAR,
  CONTENT_SUBSCRIBTION,
} from '@/constants/api';
import { contentTypes } from '@/constants/watchList';

// UTILITY
import bugsnagClient from '@/utility/bugsnagClient';
import { getLink, getSerializedParams } from '@/utility/routes';
import { showErrorNotification, getPartnerSlug } from '@/utility/saga';

// STORE
import { MovieDetailsActions, NotifActions } from '@/store/actions';
import { AuthSelectors, MovieDetailsSelectors } from '@/store/selectors';
import { getContentMediaSaga } from './content';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  getMovieDetails,
  getMovieStreamingData,
  getSimilarMovies,
  subscribeToChanges,
} = MovieDetailsActions;
const { pushSuccessNotificationAction } = NotifActions;

export function* getMovieDetailsSaga(action) {
  yield put(getMovieDetails.start());

  const { movie_id, onError, clearCache } = action.payload;
  const url = getLink(MOVIE_DETAILS, { movie_id });

  try {
    const { data: respData } = yield axios.get(url, { clearCacheEntry: clearCache });

    yield fork(getContentMediaSaga, {
      payload: {
        content_id: respData.movie.id,
        type: contentTypes.movie,
      },
    });

    yield put(getMovieDetails.success({ data: respData }));
  } catch (error) {
    const status = error.response?.status;

    if ([422, 404].includes(status)) {
      if (onError) onError();

      bugsnagClient.notify(error, event => {
        event.severity = 'info';
        event.errors[0].errorMessage += `. Movie slug: ${movie_id}`;
      });
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(getMovieDetails.fail());
  }
}

export function* getMovieStreamingDataSaga(action) {
  const { movie_id, isTemp = false } = action.payload;
  const streamingDataFetched = window.history.state?.usr?.streamingDataFetched;

  yield put(getMovieStreamingData.start({ isTemp }));

  const url = getLink(MOVIE_STREAMING_DATA, { movie_id });

  try {
    let data = null;
    const dataTemp = yield select(MovieDetailsSelectors.getStreamingDataTemp);

    if (streamingDataFetched && dataTemp) {
      data = dataTemp;
    } else {
      const partner = yield call(getPartnerSlug);
      const serializedParams = getSerializedParams({ partner });
      const { data: respData } = yield axios.get(url, serializedParams);

      data = respData;
    }

    yield put(getMovieStreamingData.success({ data, isTemp }));
  } catch (error) {
    if (error?.response?.status !== 422) {
      yield call(showErrorNotification, error);
    }

    yield put(getMovieStreamingData.fail({ isTemp }));
  }
}

export function* getSimilarMoviesSaga(action) {
  yield put(getSimilarMovies.start());

  const { movie_id } = action.payload;
  const url = getLink(MOVIE_SIMILAR, { movie_id });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getSimilarMovies.success({ data: respData['similar-content'] }));
  } catch (error) {
    if (error?.response?.status !== 422) {
      yield call(showErrorNotification, error);
    }

    yield put(getSimilarMovies.fail());
  }
}

export function* subscribeToChangesSaga(action) {
  yield put(subscribeToChanges.start());

  const { data, callback } = action.payload;

  try {
    const {
      data: { status },
    } = yield axios.post(CONTENT_SUBSCRIBTION, data);

    const isAuth = yield select(AuthSelectors.getIsAuth);

    yield put(subscribeToChanges.success({ content_id: data.content_id, isAuth, status }));

    if (callback) callback();

    yield put(
      pushSuccessNotificationAction(
        status
          ? t('notification.successfullySubscribedEmail')
          : t('notification.successfullyUnsubscribedEmail'),
      ),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(subscribeToChanges.fail());
  }
}
