import { createAction } from '@/utility/actions';

import {
  CONTENT_GET_MEDIA,
  CONTENT_CHANGE_STATUS,
  CONTENT_GET_REVIEWS,
  CONTENT_ADD_REVIEW,
} from './types';

/**
 * getContentMedia - content_id, type
 */
export const getContentMedia = createAction(CONTENT_GET_MEDIA);

/**
 * changeContentStatus - content_id, status
 */
export const changeContentStatus = createAction(CONTENT_CHANGE_STATUS);

/**
 * getContentReviews - content_id (id|slug), params, clearCache
 */
export const getContentReviews = createAction(CONTENT_GET_REVIEWS);

/**
 * addContentReview - content_id (id|slug), data, onSuccess
 */
export const addContentReview = createAction(CONTENT_ADD_REVIEW);

export default {
  getContentMedia,
  changeContentStatus,
  getContentReviews,
  addContentReview,
};
