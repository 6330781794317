import copy from 'copy-to-clipboard';

// LOCALE
import { t } from '@/locale/i18n';

// CONSTANTS
import { pageSpeedBots } from '@/constants/common';

// UTILITY
import store from '@/utility/store';
import { getExternalUrlParams } from '@/utility/analytics';
import { checkIsAbsoluteUrl } from '@/utility/routes';

const decodeURI = search => {
  let decodedUrl = search;

  try {
    decodedUrl = decodeURIComponent(search);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('decode-error', e);
  }

  return decodedUrl;
};

export const delay = ms => {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
};

export const trimCharacters = value => {
  // return value.replace(/[^\d.-]/g, '');
  return value.replace(/[^\d]/g, '');
};

export function getValidUrl(url = '') {
  const isAbsoluteUrl = checkIsAbsoluteUrl(url);
  let newUrl = decodeURI(url);

  newUrl = newUrl.trim().replace(/\s/g, '');

  if (!isAbsoluteUrl) return newUrl;

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }

  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
}

export const updateUrl = (url = '', params = {}) => {
  if (!url) return url;

  let updatedUrl = getValidUrl(url);

  Object.keys(params).forEach(param => {
    updatedUrl = updatedUrl.replace(`{{${param}}}`, params[param]);
  });

  return updatedUrl;
};

export function formatExtLinks(text, params) {
  const extParams = getExternalUrlParams(params);

  // Update links with href
  let newText = text.replace(/href=".*?"/gim, url => {
    return updateUrl(url, extParams);
  });

  // Update links with src
  newText = newText.replace(/src=".*?"/gim, url => {
    return updateUrl(url, extParams);
  });

  return newText;
}

export const sendParentMessage = data => {
  if ('parentIFrame' in window) {
    window.parentIFrame.sendMessage(data);
  }
};

export const serializeArray = (data = {}) => {
  return Object.keys(data).map(key => ({
    name: key,
    value: data[key],
  }));
};

export const getPathName = href => {
  const link = document.createElement('a');
  link.href = href || '';

  return link.pathname;
};

export const getArticle = word => {
  const vowels = ['a', 'e', 'i', 'o', 'u', 'A', 'E', 'I', 'O', 'U'];
  const character = word.charAt(0);

  return vowels.includes(character) ? 'an' : 'a';
};

export const convertHTMLEntity = text => {
  if (!text) return '';

  const span = document.createElement('span');

  return text.replace(/&[#A-Za-z0-9]+;/gi, entity => {
    span.innerHTML = entity;
    return span.innerText;
  });
};

export const removeHTMLEntity = text => {
  const stripedHtml = text.replace(/<[^>]+>/g, '');

  return stripedHtml;
};

export const formatPhoneMask = phone => {
  return phone.toString().replace(/^(\d{3})(\d{3})(\d+).*/, '$1-$2-$3');
};

export const getSearchParams = (search, decode = true) => {
  const decodedUrl = decode ? decodeURI(search) : search;
  const params = new URLSearchParams(decodedUrl);

  return params;
};

export const storageAvailable = () => {
  try {
    store.set('test', 'test');
    store.remove('test');
    return true;
  } catch (e) {
    return false;
  }
};

export const localStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

export const getZipcodeFromGoogleAddress = address => {
  let zipcode = '';
  address.address_components.forEach(item => {
    if (item.types.includes('postal_code')) {
      zipcode = item.long_name;
    }
  });

  return zipcode;
};

export const getRuntime = duration => {
  let runtime = null;

  if (duration >= 20 && duration < 40) {
    runtime = t('common.runtimes.halfHour');
  }

  if (duration >= 40 && duration < 70) {
    runtime = t('common.runtimes.hour');
  }

  return runtime;
};

export const getFavicon = () => document.getElementById('favicon');

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const isRequiredField = (field, array) => {
  return array.includes(field);
};

export function popupCenter(url, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;

  const newWindow = window.open(
    url,
    null,
    `scrollbars=yes, width=${top > 0 ? w : w * 0.75}, height=${
      top > 0 ? h : h * 0.75
    }, top=${top}, left=${left}`,
  );

  // Puts focus on the newWindow
  if (window?.focus) {
    if (newWindow?.focus?.call) {
      newWindow.focus();
    }
  }
}

export function getFormatedGenres(genres, separator = '/') {
  const names = genres.map(genre => genre.name);

  return names.join(separator);
}

export function scrollToRef(ref, offsetTop = 0) {
  if (ref.current && window.scroll) {
    try {
      window.scroll({ top: ref.current.offsetTop + offsetTop, left: 0, behavior: 'smooth' });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

export function cleanTextFromTags(string) {
  return string ? string.replace(/<\/?[^>]+(>|$)/g, '') : '';
}

export const getBillingAddress = ({ line1, line2, city, state, postal_code }) => {
  const line3 = [city, state, postal_code].filter(Boolean).join(', ');
  return [line1, line2, line3].filter(Boolean).join(',\n');
};

export const isPageSpeedBot = () => {
  let isBot = false;
  const userAgent = window.navigator.userAgent;

  pageSpeedBots.forEach(bot => {
    if (userAgent.indexOf(bot) > -1) {
      isBot = true;
    }
  });

  return isBot;
};

export function isEvent(e) {
  return (e?.nativeEvent || e) instanceof Event;
}

export function getLang() {
  if (navigator.languages !== undefined) return navigator.languages[0];

  return navigator.language;
}

export function scrollToSection(sectionId) {
  if (!sectionId) return;

  const element = document.getElementById(sectionId);

  if (element && window.scrollTo) {
    const topPos = element.getBoundingClientRect().top + window.pageYOffset - 120;

    window.scrollTo({
      top: topPos,
      behavior: 'smooth', // smooth scroll
    });
  } else {
    window.location.hash = `#${sectionId}`;
  }
}

/**
 * shareInfo - data - title, text, url
 */
export const shareInfo = async (data = {}) => {
  const url = data.url || '';

  try {
    await navigator.share(data);
  } catch (e) {
    copy(url);
  }
};

export default trimCharacters;
