import { css } from 'styled-components';

const fidiumStyles = css`
  .backtohome {
    a {
      display: none !important;
    }
  }
`;

export default fidiumStyles;
