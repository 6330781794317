import produce from 'immer';

import {
  getContentMedia,
  changeContentStatus,
  getContentReviews,
  addContentReview,
} from '../actions/content';

const initialState = {
  reviews: {
    data: [],
    user_review: null,
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 3,
    },
  },
  loadings: {
    media: false,
    actions: {},
    reviews: false,
    addReview: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET CONTENT MEDIA
    case getContentMedia.types.START:
      state.loadings.media = true;
      return state;

    case getContentMedia.types.SUCCESS:
    case getContentMedia.types.FAIL:
      state.loadings.media = false;
      return state;

    // GET CONTENT MEDIA
    case changeContentStatus.types.START:
      state.loadings.actions[action.payload.content_id] = true;
      return state;

    case changeContentStatus.types.SUCCESS:
    case changeContentStatus.types.FAIL:
      state.loadings.actions[action.payload.content_id] = false;
      return state;

    // GET REVIEWS
    case getContentReviews.types.START:
      state.loadings.reviews = true;
      return state;

    case getContentReviews.types.SUCCESS:
      state.reviews.data = action.payload.data;
      state.reviews.meta = action.payload.meta;
      state.reviews.user_review = action.payload.user_review;
      state.loadings.reviews = false;
      return state;

    case getContentReviews.types.FAIL:
      state.loadings.reviews = false;
      return state;

    // ADD REVIEW
    case addContentReview.types.START:
      state.loadings.addReview = true;
      return state;

    case addContentReview.types.SUCCESS:
      state.loadings.addReview = false;
      return state;

    case addContentReview.types.FAIL:
      state.loadings.addReview = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
