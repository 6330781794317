import React from 'react';
import { useSelector } from 'react-redux';
import { Routes as RouterRoutes, Route } from 'react-router-dom';

// CONSTANTS
import * as routePaths from '@/constants/routes';
import {
  LOCATIONS_MOVIES_WL_PAGE,
  LOCATIONS_MY_APPS_HAVE_PAGE,
  LOCATIONS_SHOWS_WL_PAGE,
  LOCATIONS_WL_PAGE,
} from '@/constants/locations';

// UTILITY
import { lazyWithRetry, getLink } from '@/utility/routes';

// HOC
import RouterLayout from '@/hoc/RouterLayout';

// COMPONENTS
import Redirect from '@/components/Redirect';
import { ROUTE_PARTNER_PROFILE_WATCHLISTS, ROUTE_PROFILE_WATCHLISTS } from '@/constants/routes';

const Home = lazyWithRetry(() => import('@/containers/Home'));
const HomeAuth = lazyWithRetry(() => import('@/containers/HomeAuth'));
const PartnerHome = lazyWithRetry(() => import('@/containers/PartnerHome'));
const PartnerHomeAuth = lazyWithRetry(() => import('@/containers/PartnerHomeAuth'));
const PartnerFakeHome = lazyWithRetry(() => import('@/containers/PartnerFakeHome'));
const Unsubscribe = lazyWithRetry(() => import('@/containers/Unsubscribe'));
const Start = lazyWithRetry(() => import('@/containers/Start'));
const Channels = lazyWithRetry(() => import('@/containers/Channels'));
const Results = lazyWithRetry(() => import('@/containers/Results'));
const StartCSR = lazyWithRetry(() => import('@/containers/StartCSR'));
const ChannelsCSR = lazyWithRetry(() => import('@/containers/ChannelsCSR'));
const ResultsCSR = lazyWithRetry(() => import('@/containers/ResultsCSR'));
const SlingBestPackage = lazyWithRetry(() => import('@/containers/SlingBestPackage'));
const PlanDetails = lazyWithRetry(() => import('@/containers/PlanDetails'));
const InternetOption = lazyWithRetry(() => import('@/containers/InternetOption'));
const InternetOffers = lazyWithRetry(() => import('@/containers/InternetOffers'));
const InternetOffersQuestions = lazyWithRetry(() =>
  import('@/containers/InternetOffers/help/IOQuestions'),
);
const InternetOffersResults = lazyWithRetry(() =>
  import('@/containers/InternetOffers/help/IOResults'),
);
const Search = lazyWithRetry(() => import('@/containers/Search'));
const ShowsAndMovies = lazyWithRetry(() => import('@/containers/ShowsAndMovies'));
const ShowsAndMoviesDiscover = lazyWithRetry(() => import('@/containers/ShowsAndMoviesDiscover'));
const MovieDetails = lazyWithRetry(() => import('@/containers/MovieDetails'));
const ShowDetails = lazyWithRetry(() => import('@/containers/ShowDetails'));
const DiscoverTutorial = lazyWithRetry(() => import('@/containers/DiscoverTutorial'));
const StreamingMarketplace = lazyWithRetry(() => import('@/containers/StreamingMarketplace'));
const StreamingMarketplaceDiscover = lazyWithRetry(() =>
  import('@/containers/StreamingMarketplaceDiscover'),
);
const AppDetails = lazyWithRetry(() => import('@/containers/AppDetails'));
const ChannelDetails = lazyWithRetry(() => import('@/containers/ChannelDetails'));

const Promotion = lazyWithRetry(() => import('@/containers/Promotion'));
const WPNews = lazyWithRetry(() => import('@/containers/WPNews'));
const WPInsights = lazyWithRetry(() => import('@/containers/WPInsights'));
const WPPost = lazyWithRetry(() => import('@/containers/WPPost'));
const WPAuthor = lazyWithRetry(() => import('@/containers/WPAuthor'));
const WPCategory = lazyWithRetry(() => import('@/containers/WPCategory'));
const WPPage = lazyWithRetry(() => import('@/containers/WPPage'));
const StreamingChoiceLanding = lazyWithRetry(() => import('@/containers/StreamingChoiceLanding'));
const StreamingRewards = lazyWithRetry(() => import('@/containers/StreamingRewards'));
const SRClaim = lazyWithRetry(() => import('@/containers/StreamingRewards/SRClaim'));
const SRInstruction = lazyWithRetry(() => import('@/containers/StreamingRewards/SRInstruction'));
const StreamingRewardsImage = lazyWithRetry(() =>
  import('@/containers/StreamingRewards/StreamingRewardsImage'),
);
const StreamingRewardsFunds = lazyWithRetry(() => import('@/containers/StreamingRewardsFunds'));

const PartnerTV = lazyWithRetry(() => import('@/containers/PartnerTV'));
const PartnerStreamTV = lazyWithRetry(() => import('@/containers/PartnerStreamTV'));

const BlackFriday = lazyWithRetry(() => import('@/containers/BlackFriday'));
const TermsAndConditions = lazyWithRetry(() => import('@/containers/TermsAndConditions'));
const PrivacyPolicy = lazyWithRetry(() => import('@/containers/PrivacyPolicy'));
const HowItWorks = lazyWithRetry(() => import('@/containers/HowItWorks'));
const About = lazyWithRetry(() => import('@/containers/About'));
const FAQ = lazyWithRetry(() => import('@/containers/FAQ'));
const Careers = lazyWithRetry(() => import('@/containers/Careers'));
const Position = lazyWithRetry(() => import('@/containers/Careers/Position'));
const ContactUs = lazyWithRetry(() => import('@/containers/ContactUs'));
const Starry = lazyWithRetry(() => import('@/containers/Starry'));
// const ISPPartners = lazyWithRetry(() => import('@/containers/ISPPartners'));
// const StreamingPartners = lazyWithRetry(() => import('@/containers/StreamingPartners'));
const TAndCCuriosity = lazyWithRetry(() => import('@/containers/TAndCCuriosity'));
const FreeTVLanding = lazyWithRetry(() => import('@/containers/FreeTVLanding'));
const MobileAppLanding = lazyWithRetry(() => import('@/containers/MobileAppLanding'));
const MobileAppImage = lazyWithRetry(() => import('@/containers/MobileAppLanding/MobileAppImage'));
const EmmyAwards = lazyWithRetry(() => import('@/containers/EmmyAwards'));
const BusinessApps = lazyWithRetry(() => import('@/containers/BusinessApps'));
const ConfirmationISP = lazyWithRetry(() => import('@/containers/ConfirmationISP'));
const PaymentHistory = lazyWithRetry(() => import('@/containers/PaymentHistory'));
const CardBalance = lazyWithRetry(() => import('@/containers/CardBalance'));
const Share = lazyWithRetry(() => import('@/containers/Share'));
const ChannelLineup = lazyWithRetry(() => import('@/containers/ChannelLineup'));

const Subscription = lazyWithRetry(() => import('@/containers/Subscription'));
const WatchNextTutorial = lazyWithRetry(() => import('@/containers/WatchNextTutorial'));
const WatchNext = lazyWithRetry(() => import('@/containers/WatchNext'));
const WatchNextEdit = lazyWithRetry(() => import('@/containers/WatchNextEdit'));
const WatchNextResults = lazyWithRetry(() => import('@/containers/WatchNextResults'));
const WatchList = lazyWithRetry(() => import('@/containers/WatchList'));
const Profile = lazyWithRetry(() => import('@/containers/Profile'));
const ProfileWatchlists = lazyWithRetry(() => import('@/containers/Profile/help/Watchlists'));
const ProfileWatchlistDetails = lazyWithRetry(() =>
  import('@/containers/Profile/help/Watchlists/WatchlistDetails'),
);
const ProfileMyApps = lazyWithRetry(() => import('@/containers/Profile/help/MyApps'));
const ProfileSubscription = lazyWithRetry(() => import('@/containers/Profile/help/Subscription'));
const ProfileMyResults = lazyWithRetry(() => import('@/containers/Profile/help/MyResults'));
const ProfileWatchNextResults = lazyWithRetry(() =>
  import('@/containers/Profile/help/WatchNextResults'),
);
const ProfileDetails = lazyWithRetry(() => import('@/containers/Profile/help/ProfileDetails'));
const ManageAccount = lazyWithRetry(() => import('@/containers/ManageAccount'));
const Auth0Login = lazyWithRetry(() => import('@/containers/Auth/Auth0Login'));
const AuthSocialLogin = lazyWithRetry(() => import('@/containers/Auth/AuthSocialLogin'));
const CleanLinks = lazyWithRetry(() => import('@/containers/CleanLinks'));
const StyleGuide = lazyWithRetry(() => import('@/containers/StyleGuide'));
const StyleGuideStaticPages = lazyWithRetry(() => import('@/containers/StyleGuide/StaticPages'));
const Maintenance = lazyWithRetry(() => import('@/containers/Maintenance'));
const TestButton = lazyWithRetry(() => import('@/containers/TestButton'));
const NotFound = lazyWithRetry(() => import('@/containers/NotFound'));

const env = process.env.REACT_APP_ENV;
const isDevEnv = env === 'local' || env === 'develop';

const useRoutesOptions = () => {
  const {
    maintenanceMode,
    isSimpleView,
    partnerSlug,
    isPartnerDomain,
    partnerSettings,
    isAuth,
  } = useSelector(({ app, partner, auth }) => ({
    maintenanceMode: app.maintenanceMode,
    isSimpleView: app.isSimpleView,
    partnerSlug: app.partner,
    isPartnerDomain: partner.isPartnerDomain,
    partnerSettings: partner.data?.settings || {},
    isAuth: auth.isAuth,
  }));

  const useAuthorizing = !!partnerSettings.use_authorizing;
  const showPartnerAuthCont = useAuthorizing && !isSimpleView;
  let homeComponent = isAuth ? HomeAuth : Home;
  let partnerHomeComponent = showPartnerAuthCont ? PartnerHomeAuth : PartnerHome;
  let homeType = isAuth ? 'main' : 'home';

  if (maintenanceMode) {
    homeComponent = Maintenance;
    partnerHomeComponent = Maintenance;
    homeType = '';
  } else if (isPartnerDomain) {
    if (partnerSettings.is_fake) {
      homeComponent = PartnerFakeHome;
    } else {
      homeComponent = partnerHomeComponent;
    }

    homeType = 'main';
  }

  return {
    homeType,
    homeComponent,
    partnerHomeComponent,
    maintenanceMode,
    partnerSlug,
  };
};

export const usePrefetchRoutes = () => {
  const { homeType, homeComponent, partnerHomeComponent, maintenanceMode } = useRoutesOptions();

  return [
    // Home page
    { path: routePaths.ROUTE_ROOT, type: homeType, component: homeComponent },
    {
      path: routePaths.ROUTE_PARTNER_ROOT_HUB,
      type: homeType,
      component: partnerHomeComponent,
    },
    {
      path: routePaths.ROUTE_HOME,
      type: homeType,
      component: maintenanceMode ? Maintenance : PartnerHome,
    },
    {
      path: routePaths.ROUTE_PARTNER_HOME,
      type: homeType,
      component: maintenanceMode ? Maintenance : PartnerHome,
    },
    // Unsubscribe
    { path: routePaths.ROUTE_UNSUBSCRIBE, component: Unsubscribe },
    //  Start
    { path: routePaths.ROUTE_START, component: Start },
    { path: routePaths.ROUTE_START_NOW, component: Start },
    { path: routePaths.ROUTE_VIDEO_MIGRATION, component: Start },
    { path: routePaths.ROUTE_PARTNER_FORM, component: Start },
    { path: routePaths.ROUTE_FIND_MY_BUNDLE, component: Start },
    // Channels
    { path: routePaths.ROUTE_CHANNELS, component: Channels },
    { path: routePaths.ROUTE_CHANNELS_WITH_ID, component: Channels },
    // Results
    { path: routePaths.ROUTE_RESULTS, component: Results },
    { path: routePaths.ROUTE_RESULTS_WITH_TOKEN, component: Results },
    { path: routePaths.ROUTE_VIEW_RESULTS, component: Results },
    { path: routePaths.ROUTE_VIEW_RESULTS_WITH_TOKEN, component: Results },
    // StartCSR
    { path: routePaths.ROUTE_CSR, component: StartCSR },
    { path: routePaths.ROUTE_PARTNER_CSR, component: StartCSR },
    // CSR Channels
    { path: routePaths.ROUTE_CSR_CHANNELS, component: ChannelsCSR },
    { path: routePaths.ROUTE_CSR_CHANNELS_WITH_ID, component: ChannelsCSR },
    // CSR Results
    { path: routePaths.ROUTE_CSR_RESULTS, component: ResultsCSR },
    // SLING BEST PACKAGE
    {
      path: routePaths.ROUTE_SLING_BEST_PACKAGE,
      component: SlingBestPackage,
    },
    // Plan details
    {
      type: 'main',
      path: routePaths.ROUTE_PLAN_DETAILS,
      component: PlanDetails,
    },
    { type: 'main', path: routePaths.ROUTE_SLING_AIR_TV_LANDING, component: PlanDetails },
    { type: 'main', path: routePaths.ROUTE_SLING_AIR_TV, component: PlanDetails },
    // Internet Option
    {
      type: 'main',
      path: routePaths.ROUTE_RESULTS_INTERNET_OPTION,
      component: InternetOption,
    },
    // Internet Offers
    {
      path: routePaths.ROUTE_INTERNET_OFFERS,
      type: 'main',
      component: InternetOffers,
    },
    {
      path: routePaths.ROUTE_INTERNET_OFFERS_QUESTIONS,
      component: InternetOffersQuestions,
    },
    {
      path: routePaths.ROUTE_INTERNET_OFFERS_RESULTS,
      type: 'main',
      component: InternetOffersResults,
    },
    {
      path: routePaths.ROUTE_INTERNET_OFFERS_RESULTS_WITH_TOKEN,
      type: 'main',
      component: InternetOffersResults,
    },
    // Search
    { type: 'main', path: routePaths.ROUTE_SEARCH, component: Search },
    // Shows and movies
    {
      type: 'main',
      path: routePaths.ROUTE_SHOWS_AND_MOVIES,
      component: ShowsAndMovies,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_SHOWS_AND_MOVIES_DISCOVER,
      component: ShowsAndMoviesDiscover,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_SHOWS_AND_MOVIES_MOVIE,
      component: MovieDetails,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_SHOWS_AND_MOVIES_SHOW,
      component: ShowDetails,
    },
    // Discover
    { type: 'main', path: routePaths.ROUTE_DISCOVER_TUTORIAL, component: DiscoverTutorial },
    {
      type: 'main',
      path: routePaths.ROUTE_DISCOVER,
      component: StreamingMarketplace,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_DISCOVER_SEARCH,
      component: StreamingMarketplaceDiscover,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_DISCOVER_GENRE,
      component: StreamingMarketplaceDiscover,
    },
    { type: 'main', path: routePaths.ROUTE_DISCOVER_APP, component: AppDetails },
    { type: 'main', path: routePaths.ROUTE_DISCOVER_MOVIE, component: MovieDetails },
    { type: 'main', path: routePaths.ROUTE_DISCOVER_SHOW, component: ShowDetails },
    { type: 'main', path: routePaths.ROUTE_DISCOVER_CHANNEL, component: ChannelDetails },
    // Promotion
    { type: 'main', path: routePaths.ROUTE_PROMOTION, component: Promotion },
    // WP pages
    { type: 'main', path: routePaths.ROUTE_BLOG, component: WPInsights },
    { type: 'main', path: routePaths.ROUTE_BLOG_POST, component: WPPost },
    { type: 'main', path: routePaths.ROUTE_BLOG_AUTHOR, component: WPAuthor },
    { type: 'main', path: routePaths.ROUTE_BLOG_CATEGORY, component: WPCategory },
    { type: 'main', path: routePaths.ROUTE_NEWS, component: WPNews },
    {
      type: 'main',
      path: routePaths.ROUTE_NEWS_POST,
      component: WPPost,
      componentProps: { type: 'news' },
    },
    {
      type: 'main',
      path: routePaths.ROUTE_NEWS_AUTHOR,
      component: WPAuthor,
      componentProps: { type: 'news' },
    },
    {
      type: 'main',
      path: routePaths.ROUTE_NEWS_CATEGORY,
      component: WPCategory,
      componentProps: { type: 'news' },
    },
    { type: 'main', path: routePaths.ROUTE_WP_PAGE, component: WPPage },
    // Streaming rewards page
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_REWARDS,
      component: StreamingRewards,
    },
    {
      type: 'sc-claim',
      path: routePaths.ROUTE_STREAMING_REWARDS_CLAIM,
      component: SRClaim,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_REWARDS_INSTRUCTION,
      component: SRInstruction,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_REWARDS_FUNDS,
      component: StreamingRewardsFunds,
      protection: true,
    },
    {
      path: routePaths.ROUTE_STREAMING_REWARDS_IMAGE,
      component: StreamingRewardsImage,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_CHOICE_LANDING,
      component: StreamingChoiceLanding,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_CHOICE,
      component: StreamingRewards,
    },
    {
      type: 'sc-claim',
      path: routePaths.ROUTE_STREAMING_CHOICE_CLAIM,
      component: SRClaim,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_CHOICE_INSTRUCTION,
      component: SRInstruction,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMING_CHOICE_FUNDS,
      component: StreamingRewardsFunds,
      protection: true,
    },
    {
      path: routePaths.ROUTE_STREAMING_CHOICE_IMAGE,
      component: StreamingRewardsImage,
    },
    // TV
    {
      type: 'main',
      path: routePaths.ROUTE_TV,
      component: PartnerTV,
    },
    // STREAMTV
    {
      type: 'main',
      path: routePaths.ROUTE_STREAMTV,
      component: PartnerStreamTV,
    },
    // BLACK FRIDAY
    {
      type: 'main',
      path: routePaths.ROUTE_BLACK_FRIDAY,
      component: BlackFriday,
    },
    // Static pages
    {
      type: 'main',
      path: routePaths.ROUTE_TERMS_AND_CONDITIONS,
      component: TermsAndConditions,
    },
    { type: 'main', path: routePaths.ROUTE_T_AND_C, component: TermsAndConditions },
    {
      type: 'main',
      path: routePaths.ROUTE_PRIVACY_POLICY,
      component: PrivacyPolicy,
    },
    { type: 'main', path: routePaths.ROUTE_HOW_IT_WORKS, component: HowItWorks },
    { type: 'main', path: routePaths.ROUTE_ABOUT, component: About },
    { type: 'main', path: routePaths.ROUTE_FAQ, component: FAQ },
    { type: 'main', path: routePaths.ROUTE_CAREERS, component: Careers },
    { type: 'main', path: routePaths.ROUTE_CAREERS_POSITION, component: Position },
    { type: 'main', path: routePaths.ROUTE_CONTACT_US, component: ContactUs },
    { type: 'main', path: routePaths.ROUTE_STARRY, component: Starry },
    // { type: 'main', path: routePaths.ROUTE_ISP_PARTNERS, component: ISPPartners },
    // { type: 'main', path: routePaths.ROUTE_STREAMING_PARTNERS, component: StreamingPartners },
    { type: 'main', path: routePaths.ROUTE_T_AND_CURIOSITY, component: TAndCCuriosity },
    { type: 'main', path: routePaths.ROUTE_FREE_TV, component: FreeTVLanding },
    { type: 'main', path: routePaths.ROUTE_MOBILE_APP, component: MobileAppLanding },
    { path: routePaths.ROUTE_MOBILE_APP_IMAGE, component: MobileAppImage },
    {
      type: 'main',
      path: routePaths.ROUTE_EMMY_AWARDS,
      component: EmmyAwards,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_BUSINESS_APPS,
      headerProps: {
        mainNavigation: false,
        backPartnerLink: false,
      },
      component: BusinessApps,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_CONFIRMATION_ISP,
      component: ConfirmationISP,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PAYMENT_HISTORY,
      component: PaymentHistory,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_CARD_BALANCE,
      component: CardBalance,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_SHARE,
      component: Share,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_SHARE_WITH_ID,
      component: Share,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_CHANNEL_LINEUP,
      component: ChannelLineup,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_CSR_CHANNEL_LINEUP,
      component: ChannelLineup,
    },
    // Subscription
    {
      type: 'subscription',
      path: routePaths.ROUTE_SUBSCRIPTION,
      component: Subscription,
    },
    // Watch next
    {
      type: 'main',
      path: routePaths.ROUTE_WATCH_NEXT_TUTORIAL,
      component: WatchNextTutorial,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_WATCH_NEXT,
      component: WatchNext,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_WATCH_NEXT_EDIT_WITH_TOKEN,
      component: WatchNextEdit,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_WATCH_NEXT_WITH_TOKEN,
      component: WatchNextResults,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_WATCH_NEXT_VOTE_RESULTS,
      component: WatchNextResults,
    },
    // Watchlist
    {
      type: 'main',
      path: routePaths.ROUTE_SHARED_WATCH_LIST,
      component: WatchList,
    },
    // Profile
    {
      type: 'profile',
      path: routePaths.ROUTE_PROFILE,
      component: Profile,
      protection: true,
      showAuthDialog: true,
      children: [
        {
          path: routePaths.ROUTE_PROFILE_WATCHLISTS,
          component: ProfileWatchlists,
        },
        {
          path: routePaths.ROUTE_PROFILE_WATCHLISTS_DETAILS,
          component: ProfileWatchlistDetails,
        },
        {
          path: routePaths.ROUTE_PROFILE_MY_APPS_LIST,
          component: ProfileMyApps,
        },
        {
          path: routePaths.ROUTE_PROFILE_SUBSCRIPTION_DETAIL,
          component: ProfileSubscription,
        },
        {
          path: routePaths.ROUTE_PROFILE_MY_RESULTS,
          component: ProfileMyResults,
        },
        {
          path: routePaths.ROUTE_PROFILE_WN_RESULTS,
          component: ProfileWatchNextResults,
        },
        {
          path: routePaths.ROUTE_PROFILE_DETAILS,
          component: ProfileDetails,
        },
      ],
    },
    {
      type: 'profile',
      path: routePaths.ROUTE_MANAGE_ACCOUNT,
      component: ManageAccount,
    },
    { type: 'static', path: routePaths.ROUTE_404, component: NotFound },
    { type: 'static', path: routePaths.ROUTE_NO_APP_FOUND, component: NotFound },
    { path: routePaths.ROUTE_LOGIN, component: Auth0Login },
    { path: routePaths.ROUTE_BUY_APP, component: CleanLinks },
    { path: routePaths.ROUTE_STYLEGUIDE, component: StyleGuide },
    { path: routePaths.ROUTE_STYLEGUIDE_STATIC_PAGES, component: StyleGuideStaticPages },

    // PARTNER ROUTES

    // Unsubscribe
    { path: routePaths.ROUTE_PARTNER_UNSUBSCRIBE, component: Unsubscribe },

    // Start
    { path: routePaths.ROUTE_PARTNER_START, component: Start },
    { path: routePaths.ROUTE_PARTNER_VIDEO_MIGRATION, component: Start },
    // Channels
    { path: routePaths.ROUTE_PARTNER_CHANNELS, component: Channels },
    { path: routePaths.ROUTE_PARTNER_CHANNELS_WITH_ID, component: Channels },
    // Results
    { path: routePaths.ROUTE_PARTNER_RESULTS, component: Results },
    { path: routePaths.ROUTE_PARTNER_RESULTS_WITH_TOKEN, component: Results },
    {
      path: routePaths.ROUTE_PARTNER_RESULTS_WITH_TOKEN,
      component: Results,
      exact: true,
    },
    // SLING BEST PACKAGE
    {
      path: routePaths.ROUTE_PARTNER_SLING_BEST_PACKAGE,
      component: SlingBestPackage,
    },
    // Search
    { type: 'main', path: routePaths.ROUTE_PARTNER_SEARCH, component: Search },
    // Shows and movies
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_SHOWS_AND_MOVIES,
      component: ShowsAndMovies,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_SHOWS_AND_MOVIES_DISCOVER,
      component: ShowsAndMoviesDiscover,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_SHOWS_AND_MOVIES_MOVIE,
      component: MovieDetails,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_SHOWS_AND_MOVIES_SHOW,
      component: ShowDetails,
    },
    // Discover
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_DISCOVER,
      component: StreamingMarketplace,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_DISCOVER_SEARCH,
      component: StreamingMarketplaceDiscover,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_DISCOVER_APP,
      component: AppDetails,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_DISCOVER_MOVIE,
      component: MovieDetails,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_DISCOVER_SHOW,
      component: ShowDetails,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_DISCOVER_CHANNEL,
      component: ChannelDetails,
    },
    // Promotion
    { type: 'main', path: routePaths.ROUTE_PARTNER_PROMOTION, component: Promotion },
    // WP
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_BLOG,
      component: WPInsights,
    },
    { type: 'main', path: routePaths.ROUTE_PARTNER_BLOG_POST, component: WPPost },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_BLOG_AUTHOR,
      component: WPAuthor,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_BLOG_CATEGORY,
      component: WPCategory,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_NEWS,
      component: WPNews,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_NEWS_POST,
      component: WPPost,
      componentProps: { type: 'news' },
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_NEWS_AUTHOR,
      component: WPAuthor,
      componentProps: { type: 'news' },
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_NEWS_CATEGORY,
      component: WPCategory,
      componentProps: { type: 'news' },
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_WP_PAGE,
      component: WPPage,
    },
    // Streaming rewards page
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_REWARDS,
      component: StreamingRewards,
    },
    {
      type: 'sc-claim',
      path: routePaths.ROUTE_PARTNER_STREAMING_REWARDS_CLAIM,
      component: SRClaim,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_REWARDS_INSTRUCTION,
      component: SRInstruction,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_REWARDS_FUNDS,
      component: StreamingRewardsFunds,
      protection: true,
    },
    {
      path: routePaths.ROUTE_PARTNER_STREAMING_REWARDS_IMAGE,
      component: StreamingRewardsImage,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_CHOICE_LANDING,
      component: StreamingChoiceLanding,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_CHOICE,
      component: StreamingRewards,
    },
    {
      type: 'sc-claim',
      path: routePaths.ROUTE_PARTNER_STREAMING_CHOICE_CLAIM,
      component: SRClaim,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_CHOICE_INSTRUCTION,
      component: SRInstruction,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMING_CHOICE_FUNDS,
      component: StreamingRewardsFunds,
      protection: true,
    },
    {
      path: routePaths.ROUTE_PARTNER_STREAMING_CHOICE_IMAGE,
      component: StreamingRewardsImage,
    },
    // TV
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_TV,
      component: PartnerTV,
    },
    // STREAMTV
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_STREAMTV,
      component: PartnerStreamTV,
    },
    // BLACK FRIDAY
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_BLACK_FRIDAY,
      component: BlackFriday,
    },
    // Static pages
    { type: 'main', path: routePaths.ROUTE_PARTNER_SLING_AIR_TV_LANDING, component: PlanDetails },
    { type: 'main', path: routePaths.ROUTE_PARTNER_SLING_AIR_TV, component: PlanDetails },
    { type: 'main', path: routePaths.ROUTE_PARTNER_FREE_TV, component: FreeTVLanding },
    { type: 'main', path: routePaths.ROUTE_PARTNER_MOBILE_APP, component: MobileAppLanding },
    { path: routePaths.ROUTE_PARTNER_MOBILE_APP_IMAGE, component: MobileAppImage },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_EMMY_AWARDS,
      component: EmmyAwards,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_BUSINESS_APPS,
      headerProps: {
        mainNavigation: false,
        backPartnerLink: false,
      },
      component: BusinessApps,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_CONFIRMATION_ISP,
      component: ConfirmationISP,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_CHANNEL_LINEUP,
      component: ChannelLineup,
    },
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_CSR_CHANNEL_LINEUP,
      component: ChannelLineup,
    },
    // Subscription
    {
      type: 'subscription',
      path: routePaths.ROUTE_PARTNER_SUBSCRIPTION,
      component: Subscription,
    },
    // Watch next
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_WATCH_NEXT_TUTORIAL,
      component: WatchNextTutorial,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_PARTNER_WATCH_NEXT,
      component: WatchNext,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_PARTNER_WATCH_NEXT_EDIT_WITH_TOKEN,
      component: WatchNextEdit,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_PARTNER_WATCH_NEXT_WITH_TOKEN,
      component: WatchNextResults,
    },
    {
      type: 'watch-next',
      path: routePaths.ROUTE_PARTNER_WATCH_NEXT_VOTE_RESULTS,
      component: WatchNextResults,
    },

    // Watchlist
    {
      type: 'main',
      path: routePaths.ROUTE_PARTNER_SHARED_WATCH_LIST,
      component: WatchList,
    },

    // Partner profile
    {
      type: 'profile',
      path: routePaths.ROUTE_PARTNER_PROFILE,
      component: Profile,
      protection: true,
      children: [
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_WATCHLISTS,
          component: ProfileWatchlists,
        },
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_WATCHLISTS_DETAILS,
          component: ProfileWatchlistDetails,
        },
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_MY_APPS_LIST,
          component: ProfileMyApps,
        },
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_SUBSCRIPTION_DETAIL,
          component: ProfileSubscription,
        },
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_MY_RESULTS,
          component: ProfileMyResults,
        },
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_WN_RESULTS,
          component: ProfileWatchNextResults,
        },
        {
          path: routePaths.ROUTE_PARTNER_PROFILE_DETAILS,
          component: ProfileDetails,
        },
      ],
    },
    {
      type: 'profile',
      path: routePaths.ROUTE_PARTNER_ROUTE_MANAGE_ACCOUNT,
      component: ManageAccount,
    },
    { path: routePaths.ROUTE_PARTNER_LOGIN, component: Auth0Login },
    { path: routePaths.ROUTE_PARTNER_SOCIAL_LOGIN, component: AuthSocialLogin },
    { path: routePaths.ROUTE_PARTNER_BUY_APP, component: CleanLinks },
    { path: routePaths.ROUTE_PARTNER_BUY_APP_2, component: CleanLinks },
    { path: routePaths.ROUTE_PARTNER_STYLEGUIDE, component: StyleGuide },
    { path: routePaths.ROUTE_PARTNER_STYLEGUIDE_STATIC_PAGES, component: StyleGuideStaticPages },
    // Maintenance
    { path: routePaths.ROUTE_MAINTENANCE, component: Maintenance },
  ];
};

const Routes = () => {
  const routerPaths = usePrefetchRoutes();
  const { partnerSlug, homeType, partnerHomeComponent } = useRoutesOptions();

  return (
    <RouterRoutes>
      {/* Discover Redirects */}
      <Route
        path={getLink(routePaths.ROUTE_PARTNER_DISCOVER_APP, { slug: '2793' })}
        element={<Redirect to="https://www.centurylink.com/tv/att-tv-packages" external />}
      />
      <Route
        path={getLink(routePaths.ROUTE_PARTNER_DISCOVER_APP, { slug: 'att-tv' })}
        element={<Redirect to="https://www.centurylink.com/tv/att-tv-packages" external />}
      />
      <Route
        path={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: 'black-friday' })}
        element={<Redirect to={getLink(routePaths.ROUTE_WP_PAGE, { slug: 'black-friday' })} />}
      />
      <Route
        path="/discover/app/True%20Royalty%20TV"
        element={<Redirect to={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: 'true' })} />}
      />
      <Route
        path={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: '2793' })}
        element={<Redirect to="https://www.centurylink.com/tv/att-tv-packages" external />}
      />
      <Route
        path={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: 'att-tv' })}
        element={<Redirect to="https://www.centurylink.com/tv/att-tv-packages" external />}
      />

      {/* COMMON ROUTES */}
      {routerPaths.map(({ path, children, ...props }, i) => (
        <Route path={path} key={i} element={<RouterLayout {...props} />}>
          <>
            {children
              ? children.map(({ path: childrenPath, component: ChildrenComponent }, k) => {
                  return (
                    <Route
                      path={childrenPath.replace(`${path}/`, '')}
                      element={<ChildrenComponent />}
                      key={k}
                    />
                  );
                })
              : null}
          </>
        </Route>
      ))}
      {/* Each route should be wrapped by own condition */}
      {isDevEnv && (
        <Route
          path={routePaths.ROUTE_TESTBUTTON}
          element={<RouterLayout type="main" component={TestButton} />}
        />
      )}

      {/* REDIRECTS */}
      <Route
        path={routePaths.ROUTE_STARRY_TEST}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_ISP} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route path={routePaths.ROUTE_START_TEST} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_START_OLD_TEST}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_FORM} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_FORM_RESULTS}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_REDIRECT} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_CHANNELS_NEED}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_LOADING} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_RUN_SCHED_TASKS}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_TEST_STUFF} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route path={routePaths.ROUTE_CHILDREN} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route path={routePaths.ROUTE_ROKU_DB} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_ROKU_DB_APPS}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route
        path={routePaths.ROUTE_ROKU_DB_APP}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_TEST_HOME} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route path={routePaths.ROUTE_ERROR_LOG} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_RESET_IDENTIFY}
        element={<Redirect to={routePaths.ROUTE_ROOT} />}
      />
      <Route path={routePaths.ROUTE_KIDS} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route path={routePaths.ROUTE_GUIDEBOX} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route path={routePaths.ROUTE_PS_VUE_ALT} element={<Redirect to={routePaths.ROUTE_ROOT} />} />
      <Route
        path={routePaths.ROUTE_GET_STARTED}
        element={<Redirect to={routePaths.ROUTE_START} />}
      />
      <Route
        path={routePaths.ROUTE_CSR_START_NOW}
        element={<Redirect to={getLink(routePaths.ROUTE_PARTNER_CSR, { partner: 'start-now' })} />}
      />
      <Route
        path={routePaths.ROUTE_PROFILE_WL_MOVIES_CONTENT}
        element={
          <Redirect to={ROUTE_PROFILE_WATCHLISTS} analyticsLocation={LOCATIONS_MOVIES_WL_PAGE} />
        }
      />
      <Route
        path={routePaths.ROUTE_PROFILE_WL_SHOWS_CONTENT}
        element={
          <Redirect to={ROUTE_PROFILE_WATCHLISTS} analyticsLocation={LOCATIONS_SHOWS_WL_PAGE} />
        }
      />
      <Route
        path={routePaths.ROUTE_PROFILE_WATCH_LIST}
        element={
          <Redirect
            to={routePaths.ROUTE_PROFILE_WATCHLISTS}
            analyticsLocation={LOCATIONS_WL_PAGE}
          />
        }
      />
      <Route
        path={routePaths.ROUTE_PROFILE_WL_MOVIES_CONTENT}
        element={<Redirect to={ROUTE_PROFILE_WATCHLISTS} analyticsLocation={LOCATIONS_WL_PAGE} />}
      />
      <Route
        path={routePaths.ROUTE_PROFILE_MY_APPS}
        element={
          <Redirect
            to={getLink(routePaths.ROUTE_PROFILE_MY_APPS_LIST, { content: 'have' })}
            analyticsLocation={LOCATIONS_MY_APPS_HAVE_PAGE}
          />
        }
      />
      <Route
        path={routePaths.ROUTE_HOLIDAY_GIFTS}
        element={<Redirect to={routePaths.ROUTE_DISCOVER_SEARCH} />}
      />
      <Route
        path={routePaths.ROUTE_CYBER_MONDAY}
        element={<Redirect to={routePaths.ROUTE_DISCOVER_SEARCH} />}
      />
      <Route
        path={routePaths.ROUTE_DEALS}
        element={<Redirect to={`${routePaths.ROUTE_DISCOVER_SEARCH}?genres=deals&page=1`} />}
      />
      <Route
        path={routePaths.ROUTE_STARZ}
        element={<Redirect to={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: 'starz' })} />}
      />
      <Route
        path={routePaths.ROUTE_HULU}
        element={<Redirect to={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: 'hulu' })} />}
      />
      <Route
        path={routePaths.ROUTE_HULU_LIVE_ALT}
        element={<Redirect to={getLink(routePaths.ROUTE_DISCOVER_APP, { slug: 'hulu' })} />}
      />
      <Route
        path="/discover/list/genre/:slug"
        element={<Redirect to={routePaths.ROUTE_DISCOVER_GENRE} replaceParams />}
      />
      <Route
        path={routePaths.ROUTE_DISNEY_PLUS_BUNDLE}
        element={<Redirect to="https://disneyplus.bn5x.net/c/1808007/817950/9358" external />}
      />
      <Route
        path={routePaths.ROUTE_ISP_PARTNERS}
        element={<Redirect to="https://mybundlepartners.com/" external />}
      />
      <Route
        path={routePaths.ROUTE_STREAMING_PARTNERS}
        element={<Redirect to="https://mybundlepartners.com/streaming/" external />}
      />
      <Route
        path={routePaths.ROUTE_FREE_AGREEMENT}
        element={<Redirect to="https://form.jotform.com/213435004019039" external />}
      />
      <Route path={routePaths.ROUTE_LIVE_TV} element={<Redirect to={routePaths.ROUTE_FREE_TV} />} />
      <Route
        path={routePaths.ROUTE_GIFT}
        element={<Redirect to={getLink(routePaths.ROUTE_STREAMING_CHOICE, { slug: 'partners' })} />}
      />
      <Route
        path={routePaths.ROUTE_WP_BLACK_FRIDAY}
        element={<Redirect to={routePaths.ROUTE_BLACK_FRIDAY} />}
      />

      {/* PARTNER REDIRECTS */}
      <Route
        path={routePaths.ROUTE_PARTNER_CSR_FORM}
        element={<Redirect to={routePaths.ROUTE_PARTNER_CSR} replaceParams />}
      />
      <Route
        path={routePaths.ROUTE_PARTNER_PROFILE_WATCH_LIST}
        element={
          <Redirect
            to={ROUTE_PARTNER_PROFILE_WATCHLISTS}
            analyticsLocation={LOCATIONS_MOVIES_WL_PAGE}
          />
        }
      />
      <Route
        path={routePaths.ROUTE_PARTNER_PROFILE_WL_SHOWS_CONTENT}
        element={
          <Redirect
            to={ROUTE_PARTNER_PROFILE_WATCHLISTS}
            analyticsLocation={LOCATIONS_SHOWS_WL_PAGE}
          />
        }
      />
      <Route
        path={routePaths.ROUTE_PARTNER_PROFILE_WL_MOVIES_CONTENT}
        element={
          <Redirect to={ROUTE_PARTNER_PROFILE_WATCHLISTS} analyticsLocation={LOCATIONS_WL_PAGE} />
        }
      />
      <Route
        path={routePaths.ROUTE_PARTNER_PROFILE_MY_APPS}
        element={
          <Redirect
            to={getLink(routePaths.ROUTE_PARTNER_PROFILE_MY_APPS_LIST, {
              partner: partnerSlug,
              content: 'have',
            })}
            analyticsLocation={LOCATIONS_MY_APPS_HAVE_PAGE}
          />
        }
      />
      <Route
        path={routePaths.ROUTE_PARTNER_LIVE_TV}
        element={<Redirect to={routePaths.ROUTE_PARTNER_FREE_TV} replaceParams />}
      />
      <Route
        path={routePaths.ROUTE_PARTNER_GIFT}
        element={
          <Redirect
            to={getLink(routePaths.ROUTE_STREAMING_CHOICE, { slug: 'partners' })}
            replaceParams
          />
        }
      />
      <Route
        path={routePaths.ROUTE_PARTNER_WP_BLACK_FRIDAY}
        element={<Redirect to={routePaths.ROUTE_PARTNER_BLACK_FRIDAY} replaceParams />}
      />

      {/* PARTNER ROOT */}
      <Route
        path={routePaths.ROUTE_PARTNER}
        element={<RouterLayout type={homeType} component={partnerHomeComponent} />}
      />
      <Route path="*" element={<RouterLayout component={NotFound} />} />
    </RouterRoutes>
  );
};

export default Routes;
